interface DictionaryDataItem {
  type: string;
  value: string;
}

export default {
  lookupDictionaryData(
    dictionaryData: Record<string, any[]>,
    ddCategoryKey: string,
    selectedValues: string[]
  ) {
    return (
      dictionaryData[ddCategoryKey]?.filter((item) =>
        selectedValues.includes(item.id)
      ) || []
    );
  },

  getSelectedValues(fieldData: any[]): string[] {
    return Array.isArray(fieldData)
      ? fieldData.map((item) => item.value).filter(Boolean)
      : [];
  },

  getDDitem(dictionaryDataState: any, ddCategory: string, ddItems: any) {
    let items: any[] = [];

    if (!dictionaryDataState[ddCategory]) return items;

    // do not display status REJECTED items
    // in migration, check why not all ID are being added to DD items materialIds array

    try {
      if (ddItems.length > 0 && ddCategory !== "") {
        items = ddItems
          // .filter((ddItem: DictionaryDataItem) => ddItem.type === "DD")
          .map((ddItem: DictionaryDataItem) => {
            const filteredItems = dictionaryDataState[ddCategory].filter(
              (ddCategoryItem: any) => ddCategoryItem.id === ddItem.value
            );
            return filteredItems[0];
          })
          .filter((ddItem: any) => ddItem !== undefined);
      }
    } catch (error) {
      console.log("🚀 ~ getDDitem ~ error:", error);
      console.log("🚀 ~ getDDitem ~ ddCategory:", ddCategory);
      console.log("🚀 ~ getDDitem ~ ddItems:", ddItems);
    }

    return items;
  },
};
