import React, { useContext, useEffect, useState } from "react";
import { isArray, isEmpty } from "lodash";
import { PageProps } from "gatsby";
import getApiData from "../../lib/getApiData";
import Layout from "../../components/layout";
import H1 from "../../components/htmlElements/h1";
import downloadObject from "../../lib/downloadObject";
import DetailsLeftColumnContent from "../../components/detailsElements/DetailsLeftColumnContent";
import DetailsRightColumnContent from "../../components/detailsElements/DetailsRightColumnContent";
import DetailsRightColumnContentOasis from "../../components/detailsElements/DetailsRightColumnContentOasis";
import { AuthenticationState } from "../../context/AuthenticationContext";
import LoadingIcon from "../../components/loadingIcon";
import encryption from "../../helpers/encryption";
import { getProjectName } from "../../lib/functions";

const messageMaterialNotAvailable = "Sorry this material is not available.";

export default function DetailsPage(props: PageProps) {
  // Extract variables from props
  const { id: materialId } = props;

  const authenticationState = useContext(AuthenticationState) || {
    token: "",
    isAuthenticated: false,
  };

  const projectName = getProjectName(true);

  const { token, isAuthenticated } = authenticationState;

  const [loaded, setLoaded] = useState<boolean>(false);
  const [viewable, setViewable] = useState<boolean>(false);

  const [materialTitle, setMaterialTitle] = useState<string>("");
  const [openAccess, setOpenAccess] = useState<boolean>(false);

  let userRightsAndSettings = "";
  const [metadata, setMetadata] = useState<object>({});
  const [userRights, setUserRights] = useState<object>({});

  const openDownloadModal = async (
    incomingDownloadFile: any,
    callbackFunction?: Function
  ) => {
    if (process.env.NODE_ENV === "development") {
      console.log(`Open download modal`);
    }

    const response = await downloadObject({
      downloadFile: incomingDownloadFile,
      materialId,
    });

    if (response.status === 200 && callbackFunction) {
      callbackFunction(response);
    }
  };

  /**
   * Fetch material details (metadata)
   */
  useEffect(() => {
    (async () => {
      const details = await getApiData({
        endpoint: `materials/${materialId}`,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-Amz-Security-Token": isAuthenticated ? token : "",
        },
      });

      if (details && details.status === 200) {
        setMetadata(details.data);
      }
    })();
  }, [materialId]);

  /**
   * Once metadata is loaded, fetch the material title if available.
   */
  useEffect(() => {
    if (!isEmpty(metadata)) {
      // IRIS Title from `instrument`
      if (
        metadata &&
        metadata.materialMetadata &&
        metadata.materialMetadata.instrument &&
        isArray(metadata.materialMetadata.instrument.title)
      ) {
        if (
          typeof metadata.materialMetadata?.instrument?.title[0] === "object"
        ) {
          if (metadata.materialMetadata.instrument.title[0]["#text"]) {
            setMaterialTitle(
              metadata.materialMetadata.instrument.title[0]["#text"]
            );
          }
        } else {
          setMaterialTitle(metadata.materialMetadata?.instrument?.title[0]);
        }
      } else {
        // We don't have title in instrument. So load record details without it.
      }

      // OASIS Title from `summary`
      if (
        metadata &&
        metadata.materialMetadata &&
        metadata.materialMetadata.summary &&
        metadata.materialMetadata.summary.summaryTitle
      ) {
        if (
          typeof metadata.materialMetadata?.summary?.summaryTitle === "object"
        ) {
          if (metadata.materialMetadata.summary.summaryTitle[0]["#text"]) {
            setMaterialTitle(
              metadata.materialMetadata.summary.summaryTitle[0]["#text"]
            );
          }
        } else {
          setMaterialTitle(metadata.materialMetadata?.summary?.summaryTitle);
        }
      } else {
        // We don't have title in summary. So load record details without it.
      }

      try {
        if (metadata) {
          if (metadata.userRights) {
            const password = `${materialId}-dti-2023`;
            userRightsAndSettings = encryption.decryptObject(
              metadata.userRights,
              password
            );
            setUserRights(userRightsAndSettings);

            if (metadata.objectMetadata.status === "published") {
              setViewable(true);
            } else {
              if (userRightsAndSettings.isAdminUser) {
                setViewable(true);
              }
              // Investigating why false for my record 0Zesr-Nmbyq
              if (userRightsAndSettings.isOwner) {
                setViewable(true);
              }
            }
          }
        }
      } catch (error) {
        console.log("🚀 ~ useEffect ~ error:", error);
      }

      if (
        metadata &&
        metadata.materialMetadata &&
        metadata.materialMetadata.summary &&
        metadata.materialMetadata.summary.summaryOriginalArticleOpenAccess
      ) {
        if (
          metadata.materialMetadata.summary.summaryOriginalArticleOpenAccess ===
          "Yes"
        ) {
          setOpenAccess(true);
        }
      }

      // When all done, set loaded to true
      setLoaded(true);
    }
  }, [metadata]);

  const openAccessLogo = openAccess ? (
    <img
      className="h-8 ml-2 inline"
      src="/images/openaccess.png"
      alt="Open Access"
    />
  ) : (
    ""
  );

  /**
   * Create the details content html
   */
  const detailsContent =
    loaded && viewable ? (
      <>
        <h1 className="text-3xl font-bold font-heading mb-5">
          {materialTitle}
          {openAccessLogo}
        </h1>

        {/* Keep this div here to fix a strange build/caching issue */}
        <div className="test" />

        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 order-2 md:col-span-4 md:order-1">
            <DetailsLeftColumnContent
              materialId={materialId}
              filesMetadata={metadata?.filesMetadata || []}
              doiReference={metadata?.objectMetadata?.doiReference}
              openDownloadModal={openDownloadModal}
              userRightsAndSettings={userRights}
              projectName={projectName}
              materialMetadata={metadata.materialMetadata}
              objectMetadata={metadata.objectMetadata}
            />
          </div>

          <div className="col-span-12 order-1 md:col-span-8 md:order-2">
            {projectName === "IRIS" ? (
              <DetailsRightColumnContent
                materialMetadata={metadata?.materialMetadata || []}
              />
            ) : (
              <DetailsRightColumnContentOasis
                materialMetadata={metadata.materialMetadata}
                objectMetadata={metadata.objectMetadata}
                filesMetadata={metadata?.filesMetadata || []}
              />
            )}
          </div>
        </div>
      </>
    ) : (
      ""
    );

  /**
   * Loading content
   */
  const loadingContent = (
    <div className="col-span-12 text-center">
      <LoadingIcon />
    </div>
  );

  const viewableContent = viewable
    ? detailsContent
    : messageMaterialNotAvailable;

  /**
   * Show the loading icon by default, once the metadata is loaded we can show the details content.
   */
  return <Layout>{loaded ? viewableContent : loadingContent}</Layout>;
}
