import React from "react";

interface OasisVideoProps {
    filesMetadata: any;
}

const OasisVideo = (props: OasisVideoProps) => {
    const { filesMetadata } = props;

    const videos = filesMetadata.map((item: any) => {
        if (item.extension === 'mp4') {
            const url = `${process.env.GATSBY_FILES_LOCATION}/${item.url}`;

            console.log("🚀 ~ videos ~ url:", url)
            return (
                <video controls="controls" className="video-js vjs-default-skin btn-block" data-setup="{}" preload="auto" id="video_download" data-label="9s161705t">
                    <source src={url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            );
        }

        return null;
    });

    return videos;
};

export default OasisVideo;
