import React from "react";
import { v4 as uuidv4 } from "uuid";

import TitleTag from "./TitleTag";
import ListTag from "./ListTag";

interface CreateListProps {
  value: object | any;
  title?: string;
  showTitle?: boolean;
}

function CreateText(props: CreateListProps) {
  const { title, showTitle } = props;
  let { value } = props;

  try {
    if (value["#text"]) {
      value = value["#text"];
    }
  } catch (error) {
    console.log("🚀 ~ CreateText ~ error:", error);
  }

  return (
    <div className="listView">
      {showTitle ? <TitleTag>{title}</TitleTag> : null}
      <ListTag>
        <li key={uuidv4()}>{value}</li>
      </ListTag>
      <br />
    </div>
  );
}

export default CreateText;

CreateText.defaultProps = {
  title: "",
  showTitle: "",
};
