import { ensureEndsWithPunctuation } from "../lib/functions";
import dictionaryDataHelpers from "./dictionaryDataHelpers";

/**
 * Function to return a creator as [lastName], [firtNameInitials]
 * @param firstName string
 * @param lastName string
 * @returns string
 */
const formatName = (firstName: string, lastName: string): string => {
  let formattedFirstName = firstName
    .split(" ")
    .map((part) => (part.length > 1 ? `${part[0]}.` : part))
    .join(" ");

  // Check if the formatted name ends with a dot
  if (formattedFirstName.endsWith(".")) {
    // Remove the last character (dot)
    formattedFirstName = formattedFirstName.slice(0, -1);
  }

  return ensureEndsWithPunctuation(`${lastName}, ${formattedFirstName}`);
};

export default {
  iris(): string {
    return "";
  },

  oasis(
    materialId: string,
    materialMetadata: any,
    objectMetadata: any,
    dictionaryDataState: any
  ): any {
    console.log("🚀 ~ materialMetadata:", materialMetadata);

    let summaryWriters = "";
    let summaryPublishingYear: any = "";
    let summaryTitle = "";
    let publicationAuthors = "";
    let publishingYear = "";
    let publicationJournalName = "";
    let oasisUrlFull = "";

    try {
      if (dictionaryDataState) {
        const writers = dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisAuthors",
          materialMetadata.summary.summaryWriter
        );

        // Convert the names into `Lastname, F. & Lastname, F.`
        if (writers) {
          let joinWith = ", ";

          writers.map((writer: any, index: any, arr: any) => {
            const name = writer.label.split(",");
            const formattedName = formatName(name[1], name[0]);

            // change last joining character
            if (index === arr.length - 2) joinWith = " & ";
            if (index === arr.length - 1) joinWith = "";

            summaryWriters += `${formattedName}${joinWith}`;
            return writer;
          });
        }
      }
    } catch (error) {
      console.log("🚀 ~ writers ~ error:", error);
    }

    try {
      if (objectMetadata && objectMetadata.createdAt) {
        const date = new Date(objectMetadata.createdAt).getFullYear();
        summaryPublishingYear = date;
      }
    } catch (error) {
      console.log("🚀 ~ summaryPublishingYear ~ error:", error);
    }

    try {
      summaryTitle = ensureEndsWithPunctuation(
        materialMetadata.summary.summaryTitle
      );
    } catch (error) {
      console.log("🚀 ~ oasis ~ error:", error);
    }

    try {
      if (dictionaryDataState) {
        const authors = dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisAuthors",
          materialMetadata.publication[0].publicationAuthor
        );

        // Convert the names into `Lastname, F. & Lastname, F.`
        if (authors) {
          let joinWith = ", ";

          authors.map((writer: any, index: any, arr: any) => {
            const name = writer.label.split(",");
            const formattedName = formatName(name[1], name[0]);

            // change last joining character
            if (index === arr.length - 2) joinWith = " & ";
            if (index === arr.length - 1) joinWith = "";

            publicationAuthors += `${formattedName}${joinWith}`;
            return writer;
          });
        }
      }
    } catch (error) {
      console.log("🚀 ~ publicationAuthors ~ error:", error);
    }

    try {
      if (
        materialMetadata.publication &&
        materialMetadata.publication.length > 0 &&
        materialMetadata.publication[0].publicationDate.label
      ) {
        publishingYear = materialMetadata.publication[0].publicationDate.label;
      }
    } catch (error) {
      console.log("🚀 ~ publicationDate ~ error:", error);
    }

    try {
      if (dictionaryDataState) {
        const journalName = dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisPublicationJournals",
          materialMetadata.publication[0].publicationJournalName
        );

        if (journalName && journalName.length > 0) {
          publicationJournalName = journalName[0].label;
        }
      }
    } catch (error) {
      console.log("🚀 ~ publicationJournalName ~ error:", error);
    }

    try {
      oasisUrlFull = `https://www.oasis-database.org/details/${materialId}`;
    } catch (error) {
      console.log("🚀 ~ oasisUrlFull ~ error:", error);
    }

    return `${summaryWriters} (${summaryPublishingYear}). ${summaryTitle} <i>OASIS Summary</i> of ${publicationAuthors} (${publishingYear}) in <i>${publicationJournalName}</i>. OASIS Database, University of York, UK. ${oasisUrlFull}`;
  },
};
